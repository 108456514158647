import React, { useState } from "react"
import "./price.scss"
import UniversalLink from "../../components/UniversalLink"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import HTMLReactParser from "html-react-parser"

const Price = ({
  ACFPriceBlock: {
    headline,
    subheadingUrl,
    subheadingUrlId,
    priceObject,
    note,
  },
}) => {
  const breakpoints = useBreakpoint()
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="price">
      <div className="container">
        <h2 className="price__headline">{headline}</h2>
        {subheadingUrl && (
          <UniversalLink
            className="price__page-link link has--arrow-icon"
            to={`${subheadingUrl.url}`}
            id={subheadingUrlId}
          >
            {subheadingUrl.title}
          </UniversalLink>
        )}
        <div className="price__navigator">
          <div className="price__navigator-tabs">
            {priceObject.map((price, index) => (
              <p onClick={() => setActiveTab(index)} key={index}>
                {price.cardHeadline}
              </p>
            ))}
          </div>
          <div className="price__border-navigatior-wrapper">
            <div
              className={`price__border-navigator ${
                activeTab == 1 ? "is--transformed" : ""
              }`}
            ></div>
          </div>
        </div>
        <div className="price__cards-wrapper">
          {priceObject.map((price, index) => (
            <div className="price__card-container" key={index}>
              <div
                className={`price__card-wrapper ${
                  breakpoints.sm ? (index === activeTab ? "fadeIn" : "") : ""
                }`}
                key={index}
              >
                <h3 className="price__card-headline">{price.cardHeadline}</h3>
                <p className="price__card-subheadline">
                  {price.cardSubheadline}
                </p>
                <p className="price-tag">
                  {price.cardPrice ? price.cardPrice : "0"}
                  {price.cents && <span className="cents">{price.cents}</span>}
                  <span>€</span>
                </p>
                <p className="price-tag-helper">Pro Monat / exkl. USt.</p>
                {price.cardUrl && (
                  <UniversalLink
                    className="price__card-url button"
                    to={`${price.cardUrl.url}`}
                    id={price.cardUrlId}
                  >
                    {price.cardUrl.title}
                  </UniversalLink>
                )}
              </div>
            </div>
          ))}
          {note && <div className="price__note">{HTMLReactParser(note)}</div>}
        </div>
      </div>
    </div>
  )
}

export default Price
