import React from "react"
import "./videoOverlay.scss"
import SVGParser from "../SVGParser"
const Overlay = props => {
  const {
    showOverlay,
    setShowOverlay,
    handlePlayVideo,
    setIsPlayingImg,
    setIsPlaying,
    children,
  } = props

  return (
    <div className={`video-overlay ${showOverlay ? "is--active" : ""}`}>
      <div className="container">
        <a
          className="video-overlay__close-btn"
          onClick={() => {
            setShowOverlay(false)
            document.body.style.overflow = "visible"
            setIsPlayingImg(false)
            setIsPlaying(false)
          }}
        >
          <SVGParser name="overlay-close" />
        </a>
        <div className="video-overlay__wrapper">
          <div
            className="video-overlay__wrapper-top"
            onClick={() => {
              handlePlayVideo()
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overlay
