import React from "react"
import SVGParser from "../../components/SVGParser"
import "./legal-docs.scss"
import UniversalLink from "../../components/UniversalLink"
import { Link as GatsbyLink } from "gatsby"

const LegalDocs = ({ ACFLegalDocs: { heading, docs } }) => {
  return (
    <div className="legal-docs">
      <div className="container">
        <div className="legal-docs__wrapper">
          <h3 className="legal-docs__heading">{heading}</h3>
          <div className="legal-docs__cards-wrapper">
            {docs.map((doc, i) => (
              <div className="legal-docs__card-wrapper" key={i}>
                <div className="legal-docs__card-container">
                  <div className="legal-docs__card-top">
                    <SVGParser name={doc.icon} classes="legal-docs__icon" />
                    <div className="legal-docs__text">{doc.text}</div>
                  </div>
                  {doc.file ? (
                    <GatsbyLink
                      target="_blank"
                      to={doc.file.mediaItemUrl}
                      className="legal-docs__link"
                      id={doc.linkId}
                    >
                      {doc.link.title}
                      <SVGParser
                        name="arrow-long-right"
                        classes="legal-docs__arrow-icon"
                      />
                    </GatsbyLink>
                  ) : (
                    doc.link && (
                      <UniversalLink
                        to={doc.link.url}
                        className="legal-docs__link"
                        id={doc.linkId}
                      >
                        {doc.link.title}
                        <SVGParser
                          name="arrow-long-right"
                          classes="legal-docs__arrow-icon"
                        />
                      </UniversalLink>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegalDocs
