import React, { useEffect, useRef, useState } from "react"
import SVGParser from "../../components/SVGParser"
import ParseHTML from "html-react-parser"

const CardBlock = ({ card, index, setActiveCard }) => {
  const ourRef = useRef(null)

  useEffect(() => {
   
    const onScroll = () => {
      const topPos = ourRef.current.getBoundingClientRect().top
      const height = ourRef.current.getBoundingClientRect().height

      let nodeStyle = window.getComputedStyle(ourRef.current)
      let slideMarginBottom = nodeStyle.getPropertyValue("margin-bottom")
      slideMarginBottom = parseInt(slideMarginBottom.replace("px", ""))

      if (topPos < 200 + slideMarginBottom && topPos + height > 200) {
        setActiveCard(index)
      } else if (index == 0 && topPos > 200) {
        setActiveCard(0)
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])
  return (
    <div ref={ourRef} className="image-text-scroll__card">
      <div className="image-text-scroll__card-icon-wrapper">
        <SVGParser name={`number-${index + 1}`} />
        <div className="image-text-scroll__card-text-wrapper">
          <h5 className="image-text-scroll__title">{card.headline}</h5>
          <div>{card.paragraph && ParseHTML(card.paragraph)}</div>
        </div>
      </div>
    </div>
  )
}

export default CardBlock
