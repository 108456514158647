import React, { useRef, useState, useEffect } from "react"
import "./imageTextScroll.scss"
import SVGParser from "../../components/SVGParser"
import Image from "../../components/Image/Image"

import ParseHTML from "html-react-parser"
import CardBlock from "./CardBlock"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const ImageTextScroll = ({ ACFImageTextScrollBlock: { cards } }) => {
  const breakpoints = useBreakpoint()
  const [activeCard, setActiveCard] = useState(0)

  const cardBlock = breakpoints.md ? (
    cards.map((card, index) => (
      <div className="image-text-scroll__card" key={index}>
        <div className="image-text-scroll__card-icon-wrapper">
          <SVGParser name={`number-${index + 1}`} />
          <h5 className="image-text-scroll__title">{card.headline}</h5>
        </div>
        <div className="image-text-scroll__img">
          <Image image={card.image} />
        </div>
        <div className="image-text-scroll__content">
          <div>{card.paragraph && ParseHTML(card.paragraph)}</div>
        </div>
      </div>
    ))
  ) : (
    <div className="image-text-scroll__cards">
      {
        <div className="image-text-scroll__images">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`image-text-scroll__img ${
                activeCard == index ? "is--shown" : ""
              }`}
            >
              <Image image={card.image} />
            </div>
          ))}
        </div>
      }
      <div className="image-text-scroll__content">
        {cards.map((card, index) => (
          <CardBlock
            key={index}
            index={index}
            card={card}
            setActiveCard={setActiveCard}
          />
        ))}
      </div>
    </div>
  )

  return (
    <div className="image-text-scroll">
      <div className="container">
        <div className="image-text-scroll__wrapper">{cardBlock}</div>
      </div>
    </div>
  )
}

export default ImageTextScroll
