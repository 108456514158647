import React from "react"
import SVGParser from "../../components/SVGParser"
import "./contact-lp.scss"
import UniversalLink from "../../components/UniversalLink"

const ContactLP = ({ ACFContactLP: { heading, subheading, icon, number } }) => {
  return (
    <div className="contact-lp">
      <div className="container">
        <div className="contact-lp__wrapper">
          <div className="contact-lp__card-top">
            <SVGParser name={icon} classes="contact-lp__icon" />
            <h6 className="contact-lp__heading">{heading}</h6>
            <div className="contact-lp__subheading">{subheading}</div>
            {number && (
              <UniversalLink
                to={number.url}
                className={`link contact-lp__link`}
              >
                {number.title}
              </UniversalLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactLP
