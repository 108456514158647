import React from "react"
import "./cookies-header.scss"
import UniversalLink from "../../components/UniversalLink"

const CookiesHeader = ({
  ACFCookiesHeaderBlock: { heading, subheading, link, linkId },
}) => {
  return (
    <div className="cookies-header">
      <div className="container">
        <div className="cookies-header__wrapper">
          <h3 className="cookies-header__heading">{heading}</h3>
          <div className="cookies-header__subheading">{subheading}</div>
          {/* {link && (
            <UniversalLink to={link.url} className="link  has--arrow-icon" id={linkId}>
              {link.title}
            </UniversalLink>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default CookiesHeader
