import React from "react"
import "./quotesAndCards.scss"
import UniversalLink from "../../components/UniversalLink"
import Image from "../../components/Image/Image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const QuotesAndCards = ({ ACFQuotesAndCardsBlock }) => {
  const breakpoints = useBreakpoint()

  const isSingle = ACFQuotesAndCardsBlock.card.length === 1

  function isOdd(num) {
    return num % 2
  }

  return (
    <div className={`quotes-and-cards ${isSingle === 1 ? "is--one-card" : ""}`}>
      <div className="container">
        <div className="quotes-and-cards__cards-wrapper">
          {ACFQuotesAndCardsBlock.card.map((card, index) => {
            const slideAnimation =
              isOdd(index) || (isSingle && card.rowreverse)
                ? "slide-left"
                : "slide-right"

            return (
              <div
                className={`quotes-and-cards__card-wrapper ${
                  card.rowreverse && "quotes-and-cards__row-reverse"
                }`}
                key={index}
              >
                <div className={`quotes-and-cards__card-text-wrapper`}>
                  <h4
                    className="quotes-and-cards__heading"
                    data-sal={slideAnimation}
                    dangerouslySetInnerHTML={{ __html: card.heading }}
                  ></h4>
                  <p
                    className="quotes-and-cards__subheading"
                    data-sal={slideAnimation}
                    data-sal-delay={150}
                  >
                    {card.subheading}
                  </p>
                  {card.link ? (
                    <div data-sal={slideAnimation} data-sal-delay={450}>
                      <UniversalLink
                        to={card.link.url}
                        className="quotes-and-cards__button button"
                        data-sal={slideAnimation}
                        data-sal-delay={300}
                        id={card.linkId}
                      >
                        {card.link.title}
                      </UniversalLink>
                    </div>
                  ) : null}
                </div>
                {card.image && (
                  <div
                    data-sal="fade-in"
                    className="quotes-and-cards__img-wrapper"
                  >
                    <Image image={card.image} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default QuotesAndCards
