import React from "react"
import "./cardPrice.scss"
import parseHTML from "html-react-parser"
import UniversalLink from "../../components/UniversalLink"

const CardPrice = ({
  ACFCardPrice: { link, linkId, heading, button, buttonId, price, cents, note },
}) => {
  return (
    <div className="card-price">
      <div className="container">
        <div className="card-price__wrapper">
          <h3 className="card-price__heading">
            {heading} {note ? <sup>1</sup> : ""}
          </h3>
          {link && (
            <UniversalLink
              to={link.url}
              className="card-price__link link has--arrow-icon"
              id={linkId}
            >
              {link.title}
            </UniversalLink>
          )}
          <p className="price-tag">
            {price ? parseHTML(price) : "0"}
            {cents && <span className="cents">{cents}</span>}
            <span>€</span>
          </p>
          <p className="price-tag-helper">Pro Monat / exkl. USt.</p>
          {button && (
            <UniversalLink
              className="price__card-url button"
              to={button.url}
              id={buttonId}
            >
              {button.title}
            </UniversalLink>
          )}
        </div>
        {note ? (
          <div className="card-price__note">
            <sup>1&nbsp;</sup>
            {parseHTML(note)}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
export default CardPrice
