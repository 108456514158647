import React from "react"
import "./in-the-press.scss"
import UniversalLink from "../../components/UniversalLink"
import Image from "../../components/Image/Image"
const InThePress = ({ ACFInThePress: { heading, cards } }) => {
  return (
    <div className="in-the-press">
      <div className="container">
        <div className="in-the-press__wrapper">
          <h3 className="in-the-press__heading">{heading}</h3>
          <div className="in-the-press__cards-wrapper">
            {cards.map((card, i) => (
              <div className="in-the-press__card-wrapper" key={i}>
                <div className="in-the-press__card-container">
                  <div className="in-the-press__card-top">
                    {card.logo && (
                      <Image
                        classes="in-the-press__img-wrapper"
                        image={card.logo}
                      />
                    )}
                    <h6 className="in-the-press__text">{card.heading}</h6>
                  </div>
                  {card.link && (
                    <UniversalLink
                      to={card.link.url}
                      className="link has--arrow-icon"
                      id={card.linkId}
                    >
                      {card.link.title}
                    </UniversalLink>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InThePress
