import React, { useEffect, useState, useContext } from "react"
import CookiesObject from "universal-cookie"
import CookieHelper from "./CookieHelper"
import config from "../../../config"
import notificationContext from "../../context/NotificationContext"

import "./cookie.scss"

const Cookie = ({ ACFCookieBlock: { cookies } }) => {
  const cookiesObj = new CookiesObject()
  const [cookiesNameArray, setCookiesNameArray] = useState({})
  const { setMessage } = useContext(notificationContext)

  useEffect(() => {
    cookies.forEach(cookieElment => {
      const obj = {}
      obj[cookieElment.cookieName] =
        cookiesObj.get(cookieElment.cookieName) == "true" ||
        (cookiesObj.get(cookieElment.cookieName) == undefined &&
          (cookieElment.cookieName == config.cookieSettings.necessaryCookie ||
            cookieElment.cookieName == config.cookieSettings.functionalCookie))
          ? true
          : false
      setCookiesNameArray(prevObject => ({ ...prevObject, ...obj }))
    })
  }, [])

  const handleSubmit = () => {
    for (const property in cookiesNameArray) {
      if (property !== config.cookieSettings.necessaryCookie)
        cookiesObj.set(
          property,
          cookiesNameArray[property] == true ? "true" : "false",
          config.cookieSettings
        )
    }
    setMessage("Ihre Cookie-Einstellungen wurden gepeichert")
  }

  return (
    <div className="cookie">
      <div className="container">
        <div className="cookie__wrapper">
          {cookies &&
            cookies.map((cookie, i) => (
              <CookieHelper
                key={i}
                cookie={cookie}
                object={cookiesNameArray}
                setObject={setCookiesNameArray}
              />
            ))}
          <div className="cookie__button-wrapper">
            <div onClick={() => handleSubmit()} className={"button"}>
              Speichern
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cookie
