import React from "react"
import "./testimonial-lp.scss"
import UniversalLink from "../../components/UniversalLink"
import Image from "../../components/Image/Image"

const TestimonialLP = ({ ACFTestimonialLP: { card, image } }) => {
  return (
    <div className="testimonial-lp">
      <div className="container">
        <div className="testimonial-lp__wrapper">
          <div className="testimonial-lp__card">
            {image && (
              <div className="testimonial-lp__img-wrapper">
                <Image image={image} />
              </div>
            )}
            <div className="testimonial-lp__card-content">
              {card.image && (
                <div
                  className="testimonial-lp__card-img-wrapper"
                  data-sal="fade-in"
                >
                  <Image image={card.image} />
                </div>
              )}
              <div className="testimonial-lp__card-text-wrapper">
                <h4
                  className="testimonial-lp__heading"
                  data-sal={"slide-right"}
                  data-sal-delay={300}
                >
                  {card.heading}
                </h4>
                <div
                  className="testimonial-lp__subheading"
                  data-sal={"slide-right"}
                  data-sal-delay={300}
                >
                  {card.subheading}
                </div>
                {card.link && (
                  <div data-sal={"slide-right"} data-sal-delay={450}>
                    <UniversalLink
                      to={card.link.url}
                      className={`button testimonial-lp__link`}
                      id={card.linkId}
                    >
                      {card.link.title}
                    </UniversalLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialLP
