import React, { useState, useEffect } from "react"
import "./texts-with-background.scss"

const TextsWithBackground = ({
  ACFTextsWithBackground: { cards, heading },
}) => {
  const [arrayOfCards, setArrayOfCards] = useState([])

  useEffect(() => {
    const arr = [...cards]
    const resultArr = []
    for (let i = 0; i <= Math.ceil(arr.length / 2); i = i + 2) {
      resultArr.push(
        <div className="texts-with-background__two-card-wrapper" key={i}>
          <div className="texts-with-background__card-wrapper">
            <div className="texts-with-background__card">
              <h4>{arr[i].text}</h4>
            </div>
          </div>
          {arr[i + 1] && (
            <div className="texts-with-background__card-wrapper">
              <div className="texts-with-background__card">
                <h4> {arr[i + 1].text}</h4>
              </div>
            </div>
          )}
        </div>
      )
    }
    setArrayOfCards([...resultArr])
  }, [])

  return (
    <div className="texts-with-background">
      <div className="container">
        <div className={`texts-with-background__wrapper`}>
          <h4 className="texts-with-background__heading">{heading}</h4>
          <div className="texts-with-background__cards-wrapper">
            {arrayOfCards}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextsWithBackground
