import React, { useState, useEffect } from "react"
import Parser from "html-react-parser"
import Switch from "react-switch"
import SVGParser from "../../components/SVGParser"
import contentParser from "gatsby-wpgraphql-inline-images"
import config from "../../../config"
import { Collapse } from "react-collapse"

import "./cookie.scss"

const CookieHelper = ({ cookie, object, setObject }) => {
  const [show, setShow] = useState(false)
  const [expand, setExpanded] = useState(false)

  useEffect(() => {
    setShow(object[cookie.cookieName])
  }, [object])

  const handleChange = () => {
    object[cookie.cookieName] = !object[cookie.cookieName]
    setObject({ ...object })
    setShow(!show)
  }

  return (
    <div className="cookie__one-cookie-wrapper">
      <div className="cookie__name-wrapper">
        <h5 className="cookie__heading">{cookie.heading}</h5>
        <div className="cookie__switch-wrapper">
          <div className="cookie__switch-helper">
            {cookie.cookieName === config.cookieSettings.necessaryCookie
              ? "immer an"
              : show
              ? "an"
              : "aus"}
          </div>
          <Switch
            onChange={() => handleChange()}
            checked={show !== undefined && show}
            handleDiameter={24}
            disabled={
              cookie &&
              cookie.cookieName === config.cookieSettings.necessaryCookie
            }
            activeBoxShadow=""
            offColor="#ccd5e3"
            onColor="#0064FE"
            onHandleColor={false}
            offHandleColor="#ffffff"
            onHandleColor="#ffffff"
            height={28}
            width={56}
            borderRadius={24}
            uncheckedHandleIcon={
              <SVGParser name="mobile-menu-close" classes={"cookie__icon"} />
            }
            uncheckedIcon={<> </>}
            checkedIcon={<> </>}
            checkedHandleIcon={
              <SVGParser name="check-small-bold" classes={"cookie__icon"} />
            }
            className="react-switch"
            id="small-radius-switch"
          />
        </div>
      </div>
      {cookie.text && <div className="cookie__text">{Parser(cookie.text)}</div>}
      <div
        className={`cookie__expand ${expand ? "is--expanded" : ""}`}
        onClick={() => setExpanded(!expand)}
      >
        {cookie.expandButton}
      </div>
      <Collapse isOpened={expand}>
        <div className="cookie__table-wrapper">
          <table className="cookie__table">
            <thead>
              {cookie.isTracking ? (
                <tr>
                  <th>Name</th>
                </tr>
              ) : (
                <tr>
                  <th>Cookie Name</th>
                  <th>Beschreibung</th>
                  <th>Retention Period</th>
                  <th>Service Name</th>
                </tr>
              )}
            </thead>
            {cookie.cookies && (
              <tbody>
                {cookie.cookies.map((cookieRow, i) =>
                  cookie.isTracking ? (
                    <tr key={i}>
                      <td>
                        <a href={cookieRow.serviceName}>{cookieRow.name}</a>
                      </td>
                    </tr>
                  ) : (
                    <tr key={i}>
                      <td>{cookieRow.name}</td>
                      <td>
                        {contentParser(
                          { content: cookieRow.text },
                          config.inlineImagesOptions
                        )}
                      </td>
                      <td>{cookieRow.retention}</td>
                      <td>{cookieRow.serviceName}</td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </table>
        </div>
      </Collapse>
    </div>
  )
}

export default CookieHelper
