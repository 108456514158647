import React from "react"
import SVGParser from "../../components/SVGParser"
import "./press-releases.scss"
import UniversalLink from "../../components/UniversalLink"

const PressReleases = ({
  ACFPressReleases: {
    heading,
    pressKontakt: { eMail, heading: pressKontaktHeading, icon, text },
    releases,
  },
}) => {
  return (
    <div className="press-releases">
      <div className="container">
        <div className="press-releases__wrapper">
          <h3 className="press-releases__heading" lang="de">
            {heading}
          </h3>

          <div className="press-releases__releases-wrapper">
            {releases.map((release, i) => (
              <div className="press-releases__release-wrapper" key={i}>
                <div className="press-releases__date">
                  {release.date[0] == "0"
                    ? release.date.substring(1)
                    : release.date}
                </div>
                <h6 className="press-releases__release-heading">
                  {release.heading}
                </h6>
                {release.link && (
                  <UniversalLink
                    to={release.link.url}
                    className="link has--arrow-icon"
                    id={release.linkId}
                  >
                    {release.link.title}
                  </UniversalLink>
                )}
              </div>
            ))}
          </div>
          <div className="press-releases__press-kontakt-wrapper">
            <SVGParser
              name={icon}
              classes="press-releases__press-kontakt-icon"
            />
            <h5 className="press-releases__press-kontakt-heading">
              {pressKontaktHeading}
            </h5>
            <div className="press-releases__press-kontakt-text">{text}</div>
            {eMail && (
              <UniversalLink
                to={`mailto:${eMail}`}
                className={`press-releases__press-kontakt-email`}
              >
                {eMail}
              </UniversalLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PressReleases
