import React, { useState } from "react"
import "./mobile-pricing.scss"
import UniversalLink from "../../components/UniversalLink"
import Parse from "html-react-parser"
import SVGParser from "../../components/SVGParser"

const MobilePricing = ({ ACFPricing: { note, cards, features } }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="mobile-pricing">
      <div className="container">
        <div className="mobile-pricing__navigator">
          <div className="mobile-pricing__navigator-tabs">
            {cards.map((card, index) => (
              <div
                className="mobile-pricing__navigator-name"
                onClick={() => setActiveTab(index)}
                key={index}
              >
                {card.heading}
              </div>
            ))}
          </div>
          <div className="mobile-pricing__border-navigatior-wrapper">
            <div
              className={`mobile-pricing__border-navigator ${
                activeTab == 1 ? "is--transformed" : ""
              }`}
            ></div>
          </div>
        </div>
        <div className="mobile-pricing__cards-wrapper">
          {cards.map((card, index) => (
            <div className="mobile-pricing__card-container" key={index}>
              <div
                className={`mobile-pricing__card-wrapper ${
                  index === activeTab ? "fadeIn" : "fadeOut"
                }`}
                key={index}
              >
                <div className="mobile-pricing__card-details-wrapper">
                  <h3 className="mobile-pricing__card-headline">
                    {card.heading}
                  </h3>
                  <div className="mobile-pricing__card-subheadline">
                    {card.text}
                  </div>
                  <div className="price-tag">
                    {card.price ? card.price : "0"}
                    {card.cents && <span className="cents">{card.cents}</span>}
                    <span>€</span>
                  </div>
                  <div className="price-tag-helper">Pro Monat / exkl. USt.</div>
                  {card.link && (
                    <UniversalLink
                      className="mobile-pricing__card-url button"
                      to={`${card.link.url}`}
                      id={card.linkId}
                    >
                      {card.link.title}
                    </UniversalLink>
                  )}
                </div>
                <div className="mobile-pricing__features-wrapper">
                  {features.map((feature, index) => (
                    <div
                      key={index}
                      className={`mobile-pricing__feature-name `}
                    >
                      <div className="mobile-pricing__svg-wrapper">
                        {card.heading === "Basic" ? (
                          feature.basic !== "no" ? (
                            <SVGParser name="check-outline" />
                          ) : (
                            <SVGParser name="x-circle" />
                          )
                        ) : feature.flex !== "no" ? (
                          <SVGParser name="check-outline" />
                        ) : (
                          <SVGParser name="x-circle" />
                        )}
                      </div>
                      <div className="mobile-pricing__feature-name-text">
                        {feature.featureName}&nbsp;
                        {feature.featureNote ? (
                          <span className="mobile-pricing__tooltip">
                            <SVGParser name="info-circle" />
                            <span className="mobile-pricing__tooltiptext">
                              {feature.featureNote}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="mobile-pricing__text">
                          {card.heading === "Basic" &&
                          feature.basic !== "yes" &&
                          feature.basic !== "no"
                            ? Parse(feature.basic)
                            : card.heading === "Flex" &&
                              feature.flex !== "yes" &&
                              feature.flex !== "no"
                            ? Parse(feature.flex)
                            : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mobile-pricing__note">{Parse(note)}</div>
      </div>
    </div>
  )
}

export default MobilePricing
