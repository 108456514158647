import React from "react"
import "./imageText.scss"
import SVGParser from "../../components/SVGParser"
import contentParser from "gatsby-wpgraphql-inline-images"
import config from "../../../config"
import UniversalLink from "../../components/UniversalLink"
import Image from "../../components/Image/Image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const ImageText = ({
  ACFImageText: {
    heading,
    icon,
    image,
    backgroundImage,
    link,
    linkId,
    paragraph,
    rowReverse,
    hasbackground,
    maskedImage,
  },
}) => {
  const breakpoints = useBreakpoint()
  let slideAnimation = breakpoints.md
    ? "slide-up"
    : rowReverse
    ? "slide-left"
    : "slide-right"

  let slideAnimationImage = "slide-up"
  return (
    <div className="image-text">
      <div className="container">
        <div className="image-text__cards-wrapper">
          <div
            className={`image-text__card-wrapper ${
              rowReverse ? "is--reverse" : ""
            }`}
          >
            <div className="image-text__text-wrapper">
              <SVGParser
                classes="image-text__icon"
                name={icon}
                data-sal="fade-in"
                style={{ "--sal-duration": "1500ms" }}
                data-sal-easing="ease-out"
              />
              {icon ? (
                <h4 className={`image-text__heading`} data-sal={slideAnimation}>
                  {heading}
                </h4>
              ) : (
                <h5 className={`image-text__heading`} data-sal={slideAnimation}>
                  {heading}
                </h5>
              )}
              <div
                className={`image-text__text ${!link ? "is--margin-zero" : ""}`}
                data-sal={slideAnimation}
                data-sal-delay={150}
              >
                {contentParser(
                  { content: paragraph },
                  config.inlineImagesOptions
                )}
              </div>
              {link && (
                <UniversalLink
                  to={link.url}
                  className="link has--arrow-icon"
                  data-sal={slideAnimation}
                  data-sal-delay={300}
                  id={linkId}
                >
                  {link.title}
                </UniversalLink>
              )}
            </div>

            <div className="image-text__img-wrapper">
              {image && (
                <div data-sal={slideAnimationImage}>
                  <Image
                    classes={`image-text__image ${
                      hasbackground ? "has--background" : ""
                    } ${!maskedImage ? "" : "is--not-cropped"}`}
                    image={image}
                  />
                </div>
              )}
              {backgroundImage && (
                <div
                  data-sal={slideAnimationImage}
                  data-sal-delay={150}
                  className="image-text__bg-image"
                >
                  <Image classes={``} image={backgroundImage} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ImageText
