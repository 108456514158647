import React from "react"
import Image from "../../components/Image/Image"
import "./Teaser.scss"
import UniversalLink from "../../components/UniversalLink"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import HTMLReactParser from "html-react-parser"
import Lottie from "react-lottie"
import config from "../../../config"

const Teaser = ({
  ACFTeaser: {
    subtitle,
    title,
    content,
    image,
    button,
    buttonId,
    lottie,
    landingPageTeaser,
  },
}) => {
  const breakpoints = useBreakpoint()

  return (
    <div
      className={`teaser ${lottie || image ? "has--media" : ""} ${
        landingPageTeaser ? "is--landing-page" : ""
      }`}
    >
      <div className="container">
        <div className="teaser__row">
          <div className="teaser__content">
            {subtitle ? (
              <div className="teaser__subtitle" data-sal="slide-right">
                {subtitle}
              </div>
            ) : (
              ""
            )}

            <h1
              className="teaser__title"
              data-sal="slide-right"
              data-sal-delay={150}
            >
              {HTMLReactParser(title)}
            </h1>

            {content ? (
              <div
                className="teaser__text"
                data-sal="slide-right"
                data-sal-delay={450}
              >
                {content}
              </div>
            ) : (
              ""
            )}

            {button && (
              <div data-sal="slide-right" data-sal-delay={600}>
                <UniversalLink
                  to={button.url}
                  className="teaser__button button"
                  id={buttonId}
                >
                  {button.title}
                </UniversalLink>
              </div>
            )}
          </div>

          {lottie && lottie.mediaItemUrl && !breakpoints.md ? (
            <div className="teaser__media">
              <Lottie
                options={{
                  path: lottie.mediaItemUrl,
                  loop: false,
                  rendererSettings: config.lottiRenderSettings,
                }}
              />
            </div>
          ) : image ? (
            <div
              className="teaser__media"
              data-sal="fade-in"
              data-sal-delay={150}
            >
              <Image classes={`teaser__image`} image={image} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Teaser
