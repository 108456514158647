import React, { useState, useRef, useEffect } from "react"
import "./GalleryTeaser.scss"

const GalleryTeaser = ({ ACFBlockGalleryTeaser: { title, image } }) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (image) {
      const interval = setInterval(() => {
        setIndex(index < image.length - 1 ? index + 1 : 0)
      }, 3000)
      return () => clearInterval(interval)
    }
  })
  return (
    <div className="gallery-teaser">
      <div className="gallery-teaser__slider">
        <div>
          {image &&
            image.map((element, i) => (
              <div
                key={i}
                className={`gallery-teaser__slide ${
                  index == i ? "is--active" : ""
                }`}
                style={{
                  backgroundImage: `url( ${element.localFile.publicURL})`,
                }}
              ></div>
            ))}
        </div>
      </div>
      <div className="gallery-teaser__title">
        <h1 data-sal={"slide-up"}>{title}</h1>
      </div>
    </div>
  )
}
export default GalleryTeaser
