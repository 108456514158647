import React from "react"
import "./startWithMetro.scss"
import SVGParser from "../../components/SVGParser"
import ParseHTML from "html-react-parser"
import UniversalLink from "../../components/UniversalLink"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const StartWithMetro = ({
  ACFStartWithMetro: {
    advantages,
    link,
    linkId,
    heading,
    icon,
    paragraph,
    subheading,
  },
}) => {
  const breakpoints = useBreakpoint()
  return (
    <div className="start-with-metro">
      <div className="container">
        <div className="start-with-metro__wrapper">
          <div className="start-with-metro__text-wrapper">
            <SVGParser name={icon} />
            <h3 className="start-with-metro__heading">{ParseHTML(heading)}</h3>
            <p className="start-with-metro__subheading">{subheading}</p>
            <p className="start-with-metro__text">{ParseHTML(paragraph)}</p>
            {link && !breakpoints.md && (
              <div className="start-with-metro__button-wrapper">
                <UniversalLink to={link.url} className="button" id={linkId}>
                  {link.title}
                </UniversalLink>
              </div>
            )}
          </div>
          <div className="start-with-metro__advantages-wrapper">
            {advantages.map((advantage, index) => (
              <div
                className="start-with-metro__advantage-wrapper"
                key={index}
                data-sal={"slide-up"}
              >
                <SVGParser name={`number-${index + 1}`} />
                <div className="start-with-metro__advantage-text-wrapper">
                  <h5>{advantage.heading}</h5>
                  <p>{advantage.subheading}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {link && breakpoints.md && (
          <div className="start-with-metro__button-wrapper">
            <UniversalLink to={link.url} className="button">
              {link.title}
            </UniversalLink>
          </div>
        )}
      </div>
    </div>
  )
}

export default StartWithMetro
