import React from "react"
import "./title.scss"
import contentParser from 'gatsby-wpgraphql-inline-images';
import config from "../../../config";

const Title = ({
  ACFTitleBlock: { heading, subheading, text, centerAlign },
}) => {

  return (
    <div className="title" data-sal="slide-up">
      <div className="container">
        <div className={`title__wrapper ${centerAlign ? "is--center-align" : ''}`}>
          <h3 className="title__heading">{heading}</h3>
          {subheading && <div className="title__subheading">{subheading}</div>}
          {text ? (<div className="title__text">{contentParser({content : text}, config.inlineImagesOptions)}</div>) : ''}
        </div>
      </div>
    </div>
  )
}

export default Title
