import React from "react"
import SVGParser from "../../components/SVGParser"
import "./contact.scss"
import UniversalLink from "../../components/UniversalLink"

const Contact = ({ ACFContact: { contacts } }) => {
  return (
    <div className="contact">
      <div className="container">
        <div className="contact__wrapper">
          <div className="contact__cards-wrapper">
            {contacts.map((contact, i) => (
              <div className="contact__card-wrapper" key={i}>
                <div className="contact__card-container">
                  <div className="contact__card-top">
                    <SVGParser name={contact.icon} classes="contact__icon" />
                    <h5>{contact.heading}</h5>
                    <div className="contact__text">{contact.text}</div>
                  </div>
                  {contact.link && (
                    <UniversalLink
                      to={contact.link.url}
                      className={`link ${
                        contact.arrowIconNextToTheLink ? "has--arrow-icon" : ""
                      } `}
                    >
                      {contact.link.title}
                    </UniversalLink>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
