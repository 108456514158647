import React, { useRef, useState } from "react"
import "./FeedbackForm.scss"
import axios from "axios"
import SVGParser from "../../components/SVGParser"
import UniversalLink from "../../components/UniversalLink"

const initialState = {
  success: false,
  error: false,
  isChecked: false,
  isSubjectError: false,
  isEmailError: false,
  isDescriptionError: false,
  isCheckedError: false,
  subject: "",
  email: "",
  description: "",
}

const FeedbackForm = ({
  ACFFeedbackForm: {
    title,
    subtitle,
    buttonText,
    successTitle,
    successLink,
    linkId,
  },
}) => {
  const subjectRef = useRef(null)
  const emailRef = useRef(null)
  const descriptionRef = useRef(null)

  const [
    {
      success,
      error,
      isChecked,
      isSubjectError,
      isEmailError,
      isDescriptionError,
      subject,
      email,
      description,
    },
    setState,
  ] = useState(initialState)

  const validateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleSubmit = async evt => {
    evt.preventDefault()

    if (isChecked) return

    setState(prevState => ({
      ...prevState,
      isSubjectError: subject.length == 0,
      isEmailError: !validateEmail(email),
      isDescriptionError: description.length == 0,
    }))

    if (!validateEmail(email) || description.length == 0 || subject.length == 0)
      return

    try {
      const createTicketResponse = await axios.post(
        `${process.env.GATSBY_API_METRO_URL}/freshdesk/feedback`,
        {
          subject,
          email,
          description,
        }
      )
      if (createTicketResponse.status === 201)
        setState(prevState => ({
          ...prevState,
          success: true,
        }))
      else
        setState(prevState => ({
          ...prevState,
          error: true,
        }))
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        error: true,
      }))
    }
  }

  return (
    <div className="feedback-form">
      <div className="container">
        {success ? (
          <div className="feedback-form__success-wrapper">
            <SVGParser name="check-outline-large" />
            <h5 className="feedback-form__success-title">{successTitle}</h5>
            {successLink?.url && (
              <UniversalLink className="link" to={successLink.url} id={linkId}>
                {successLink.title}
              </UniversalLink>
            )}
          </div>
        ) : (
          <div className="feedback-form__wrapper">
            <h5 className="feedback-form__title">{title}</h5>
            <div className="feedback-form__subtitle">{subtitle}</div>
            <form onSubmit={handleSubmit} noValidate>
              <div
                className={`feedback-form__group ${
                  isEmailError ? "isError" : ""
                }`}
                onClick={() => emailRef.current?.focus()}
              >
                <input
                  className="feedback-form__field"
                  type="email"
                  placeholder="E-Mail Adresse"
                  value={email}
                  onChange={e =>
                    setState(prevState => ({
                      ...prevState,
                      email: e.target.value,
                      isEmailError: !validateEmail(e.target.value),
                    }))
                  }
                  ref={emailRef}
                />
                <label className="feedback-form__label">E-Mail Adresse</label>
              </div>
              <div className="feedback-form__error">
                {email.length === 0
                  ? "Dieses Feld muss ausgefüllt werden"
                  : "Das ist keine gültige E-Mail Adresse"}
              </div>
              <div
                className={`feedback-form__group ${
                  isSubjectError ? "isError" : ""
                }`}
                onClick={() => subjectRef.current?.focus()}
              >
                <input
                  className="feedback-form__field"
                  type="text"
                  value={subject}
                  placeholder="Betreff"
                  onChange={e =>
                    setState(prevState => ({
                      ...prevState,
                      subject: e.target.value,
                      isSubjectError: e.target.value.length == 0,
                    }))
                  }
                  ref={subjectRef}
                />
                <label className="feedback-form__label">Betreff</label>
              </div>
              <div className="feedback-form__error">
                Dieses Feld muss ausgefüllt werden
              </div>
              <div
                className={`feedback-form__group isTextArea ${
                  isDescriptionError ? "isError" : ""
                }`}
                onClick={() => descriptionRef.current?.focus()}
              >
                <textarea
                  className="feedback-form__field"
                  placeholder="Ihre Nachricht"
                  value={description}
                  onChange={e =>
                    setState(prevState => ({
                      ...prevState,
                      description: e.target.value,
                      isDescriptionError: e.target.value.length == 0,
                    }))
                  }
                  ref={descriptionRef}
                  rows={6}
                ></textarea>
              </div>
              <div className="feedback-form__error">
                Dieses Feld muss ausgefüllt werden
              </div>
              <input
                value={isChecked}
                onClick={() =>
                  setState(prevState => ({
                    ...prevState,
                    isChecked: true,
                  }))
                }
                type="checkbox"
                className="feedback-form__checker"
              />

              {error && (
                <div className="feedback-form__error-wrapper">
                  <SVGParser name="x-circle" />
                  <p>
                    Leider konnten wir Ihre Anfrage nicht bearbeiten. Bitte
                    probieren Sie es später noch einmal.
                  </p>
                </div>
              )}

              <button type="submit" className="button">
                {buttonText}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export default FeedbackForm
