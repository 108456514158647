import React, { useEffect, useState } from "react"
import SVGParser from "../../components/SVGParser"
import ParseHTML from "html-react-parser"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import ArrowIcon from "../../images/icons/arrow-long-right.svg"
import contentParser from "gatsby-wpgraphql-inline-images"
import config from "../../../config"
import UniversalLink from "../../components/UniversalLink"
import "./whoWeAre.scss"
import Image from "../../components/Image/Image"
const WhoWeAre = ({
  ACFWhoWeAreBlock: {
    heading,
    content,
    subheading,
    icon,
    advantages,
    image,
    imageMobile,
  },
}) => {
  const breakpoints = useBreakpoint()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setVisible(true)
    }
    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  })
  const imgWrapper = (
    <>
      {image && imageMobile && (
        <div
          data-sal="fade-in"
          className="who-we-are__img-wrapper"
          style={visible ? { opacity: 1 } : null}
        >
          {breakpoints.lg ? (
            <Image image={imageMobile} />
          ) : (
            <Image image={image} />
          )}
        </div>
      )}
    </>
  )
  const advantagesWrapper = (
    <div className="who-we-are__advantages">
      {advantages.map((advantage, index) => (
        <div
          className="who-we-are__advantage"
          key={index}
          data-sal="slide-up"
          style={visible ? { opacity: 1 } : null}
        >
          <SVGParser
            name={advantage.icon}
            classes="who-we-are__advantage-icon"
          />
          <div className="who-we-are__advantage-text-wrapper">
            <h5>{advantage.advantageHeading}</h5>
            {advantage.advantageSubheading && (
              <UniversalLink
                className={`link ${index === 0 ? "has--arrow-icon" : ""}`}
                to={advantage.advantageSubheading.url}
              >
                {advantage.advantageSubheading.title}
              </UniversalLink>
            )}
          </div>
        </div>
      ))}
    </div>
  )
  return (
    <div className="who-we-are">
      <div className="container">
        <div className="who-we-are__wrapper">
          <div className="who-we-are__text-wrapper">
            <SVGParser name={icon} />
            <h3 className="who-we-are__heading">{ParseHTML(heading)}</h3>
            <div className="who-we-are__sub-heading">{subheading}</div>
            <div className="who-we-are__text">
              {contentParser({ content }, config.inlineImagesOptions)}
            </div>
          </div>
          {breakpoints.md ? advantagesWrapper : imgWrapper}
        </div>
        {breakpoints.md ? imgWrapper : advantagesWrapper}
      </div>
    </div>
  )
}

export default WhoWeAre
