import React from "react"
import "./downloads.scss"
import UniversalLink from "../../components/UniversalLink"

const Downloads = ({ ACFDownloads: { heading, subheading, files } }) => {
  return (
    <div className="downloads">
      <div className="container">
        <div className="downloads__wrapper">
          <div className="downloads__text-wrapper">
            <h3 className="downloads__heading">{heading}</h3>
            <div className="downloads__subheading">{subheading}</div>
          </div>
          <div className="downloads__files-wrapper">
            {files.map((file, index) => (
              <div className="downloads__file-wrapper" key={index}>
                {file.link && (
                  <>
                    <UniversalLink
                      to={file.link.url}
                      className={`downloads__link`}
                      id={file.linkId}
                    >
                      {file.link.title}
                    </UniversalLink>
                  </>
                )}
                <div className="downloads__description">{file.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Downloads
