import React, { useEffect, useState } from "react"
import UniversalLink from "../../components/UniversalLink"
import "./Faq.scss"
import Question from "./Question"
import SVGParser from "../../components/SVGParser"
import axios from "axios"

const Faq = ({ ACFFAQBlock: { heading, link, linkId, questions } }) => {
  const [questionsArr, setQuestionsArr] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const tempQuestionsArray = []
        for (const question of questions) {
          const questionResponse = await axios.get(
            `${process.env.GATSBY_API_METRO_URL}/freshdesk/faq/${question.questionId}`
          )
          tempQuestionsArray.push(questionResponse.data)
        }
        setQuestionsArr(tempQuestionsArray)
      } catch (e) {
        setError(true)
      }
    }
    getQuestions()
  }, [questions])

  if (error) return null
  return (
    <div className="faq">
      <div className="container">
        <div className="faq__wrapper">
          {heading && (
            <>
              <SVGParser name="faq-icon" classes="faq__icon" />
              <h3 className="faq__heading">{heading}</h3>
            </>
          )}
          {link && (
            <UniversalLink
              to={link.url}
              id={linkId}
              className="faq__link link has--arrow-icon"
            >
              {link.title}
            </UniversalLink>
          )}
          <div className="faq__questions-wrapper">
            {questionsArr?.map((question, i) => (
              <Question question={question} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
