import React from "react"
import "./whereToUse.scss"
import Image from "../../components/Image/Image"
const WhereToUse = ({ ACFWhereToUse: { heading, subheading, images } }) => {
  return (
    <div className="where-to-use">
      <div className="container">
        <div className="where-to-use__wrapper">
          <h3 className="where-to-use__heading">{heading}</h3>
          <div className="where-to-use__subheading">{subheading}</div>
          <div className="where-to-use__images-wrapper">
            {images.map((image, index) => (
              image.image ? <Image
                classes={`where-to-use__img-wrapper`}
                image={image.image}
                key={index}
              />
             : null
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhereToUse
