import React from "react"
import { Handle } from "rc-slider"
export function MyHandle(props) {
  const { value, dragging, index, ...rest } = props
  return (
    <Handle key={index} value={value} {...rest}>
      <div
        className={`rc-slider-tooltip rc-slider-tooltip-placement-top ${
          value < 10 ? "is--smaller-than-ten" : ""
        }`}
      >
        <div className="rc-slider-tooltip-content">
          <div className="rc-slider-tooltip-arrow"></div>
          <div className="rc-slider-tooltip-inner" role="tooltip">
            {value}
          </div>
        </div>
      </div>
    </Handle>
  )
}
