import React from "react"
import "./iconsText.scss"
import SVGParser from "../../components/SVGParser"

const IconsText = ({ ACFIconsText: { iconsWithText } }) => {
  return (
    <div className="icons-text">
      <div className="container">
        <div className={`icons-text__wrapper ${iconsWithText.length === 3 ? 'is--three': ''}`}>
          <div className="row">
          {iconsWithText.map((element, i) => (
            <div className="icons-text__text-wrapper" key={i}>
              <SVGParser name={element.icon} />
              <h6 className="icons-text__heading">{element.heading}</h6>
              <div className="icons-text__subheading">{element.subheading}</div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IconsText
