import React from "react"
import "./advantages.scss"

const Advantages = ({ ACFAdvantagesBlock: { advantages } }) => {
  return (
    <div className="advantages">
      <div className="container">
        <div className="advantages__wrapper">
          {advantages.map((advantage, index) => (
            <div className="advantages__advantage" key={index}>
              <div>
                <div className="advantages__heading">{advantage.heading}</div>
                <div className="advantages__subheading">
                  {advantage.subheading}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Advantages
