import React from "react"

import FullWidthCard from "../blocks/FullWidthCard/FullWidthCard"
import WhoWeAre from "../blocks/WhoWeAre/WhoWeAre"
import Price from "../blocks/Price/Price"
import StartWithMetro from "../blocks/StartWithMetro/StartWithMetro"
import QuotesAndCards from "../blocks/QuotesAndCards/QuotesAndCards"
import ImageText from "../blocks/ImageText/ImageText"
import Video from "../blocks/VideoText/VideoText"
import FAQ from "../blocks/Faq/Faq"
import Advantages from "../blocks/Advantages/Advantages"
import Title from "../blocks/Title/Title"
import ImageTextScroll from "../blocks/ImageTextScroll/ImageTextScroll"
import CardPrice from "../blocks/CardPrice/CardPrice"
import ParseHTML from "html-react-parser"
import Teaser from "../blocks/Teaser/Teaser"
import Calculator from "../blocks/Calculator/Calculator"
import IconsText from "../blocks/IconsText/IconsText"
import WhereToUse from "../blocks/WhereToUse/WhereToUse"
import ImageText2 from "../blocks/ImageText2/ImageText2"
import TextsWithBackground from "../blocks/TextsWithBackground/TextsWithBackground"
import Contact from "../blocks/Contact/Contact"
import LegalDocs from "../blocks/LegalDocs/LegalDocs"
import Downloads from "../blocks/Downloads/Downloads"
import InThePress from "../blocks/InThePress/InThePress"
import PressReleases from "../blocks/PressReleases/PressReleases"
import FlexTeaser from "../blocks/FlexTeaser/FlexTeaser"
import Pricing from "../blocks/Pricing/Pricing"
import GalleryTeaser from "../blocks/GalleryTeaser/GalleryTeaser"
import Partners from "../blocks/Partners/Partners"
import CookiesHeader from "../blocks/CookiesHeader/CookiesHeader"
import Cookie from "../blocks/Cookie/Cookie"
import Benefits from "../blocks/Benefits/Benefits"
import ContactLP from "../blocks/Contact-LP/Contact-LP"
import TestimonialLP from "../blocks/Testimonial-LP/Testimonial-LP"
import FeedbackForm from "../blocks/FeedbackForm/FeedbackForm"
const components = {
  ACFBlockFullWidthCard: FullWidthCard,
  ACFPriceBlock: Price,
  ACFWhoWeAreBlock: WhoWeAre,
  ACFStartWithMetro: StartWithMetro,
  ACFQuotesAndCardsBlock: QuotesAndCards,
  ACFImageText: ImageText,
  ACFVideoTextBlock: Video,
  ACFFAQBlock: FAQ,
  ACFAdvantagesBlock: Advantages,
  ACFTitleBlock: Title,
  ACFImageTextScrollBlock: ImageTextScroll,
  ACFCardPrice: CardPrice,
  ACFTeaser: Teaser,
  ACFCalculatorBlock: Calculator,
  ACFIconsText: IconsText,
  ACFWhereToUse: WhereToUse,
  ACFImageText2: ImageText2,
  ACFTextsWithBackground: TextsWithBackground,
  ACFContact: Contact,
  ACFLegalDocs: LegalDocs,
  ACFDownloads: Downloads,
  ACFInThePress: InThePress,
  ACFPressReleases: PressReleases,
  ACFFlexTeaser: FlexTeaser,
  ACFPricing: Pricing,
  ACFBlockGalleryTeaser: GalleryTeaser,
  ACFPartnersBlock: Partners,
  ACFCookiesHeaderBlock: CookiesHeader,
  ACFCookieBlock: Cookie,
  ACFBenefitsBlock: Benefits,
  ACFContactLP: ContactLP,
  ACFTestimonialLP: TestimonialLP,
  ACFFeedbackForm: FeedbackForm,
}

const convertACFProps = component => {
  // get all object keys and iterate over them
  Object.keys(component).forEach(key => {
    if (key.includes("Fields")) {
      component.data = component[key]
      delete component[key]
    }
  })

  return component
}

const ComponentParser = props => {
  let { content } = props
  if (!content) return null

  const filteredComponents = content.filter(
    component =>
      !component ||
      !(Object.keys(component).length === 0 && component.constructor === Object)
  )
  if (filteredComponents && filteredComponents.length > 0) {
    const pageComponents = filteredComponents.map((component, index) => {
      const Component = components[Object.keys(component)[0]]

      if (!Component && component?.innerBlocks?.length > 0)
        return <ComponentParser content={component?.innerBlocks} />
      else if (!Component)
        return (
          <div className="container" key={index}>
            <div className="block">{ParseHTML(component.originalContent)}</div>
          </div>
        )

      component = convertACFProps(component)

      return (
        <Component
          key={index}
          {...component}
          {...component.data}
          {...component.attributes}
        />
      )
    })

    if (pageComponents) {
      return pageComponents
    }
  }
  return null
}

export default ComponentParser
