import React, { useRef, useState, useEffect } from "react"
import ParseHTML from "html-react-parser"
import Slider from "rc-slider"
import UniversalLink from "../../components/UniversalLink"
import SVGParser from "../../components/SVGParser"
import Tooltip from "../../components/Tooltip/Tooltip"

import "rc-tooltip/assets/bootstrap.css"
import "./calculator.scss"
import { MyHandle } from "./CustomTooltip"

const TYPE_LATER = "later"
const TYPE_INSTALLMENTS = "installments"

export default ({
  ACFCalculatorBlock: {
    heading,
    laws,
    subheading,
    labelAmount,
    labelSelect,
    buttonLabelRates,
    buttonLabelLater,
    labelRate,
    labelPayLater,
    labelCosts,
    inputTooltip,
    button,
    buttonId,
  },
}) => {
  const interest = 0.065

  const formatter = new Intl.NumberFormat("de-DE", { maximumFractionDigits: 2 })
  function parseLocaleNumber(stringNumber, locale) {
    var thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, "")
    var decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, "")

    return parseFloat(
      stringNumber
        .replace(new RegExp("\\" + thousandSeparator, "g"), "")
        .replace(new RegExp("\\" + decimalSeparator), ".")
    )
  }
  const [minRange, setMinRange] = useState(2)
  const [maxRange, setMaxRange] = useState(60)
  const [rangeOutput, setRangeOutput] = useState(minRange)
  const [marks, setMarks] = useState({ 2: 2, 60: 60 })
  const [type, setType] = useState(TYPE_LATER)
  const inputRef = useRef(null)
  const inputRef2 = useRef(null)
  const [euroInput, setEuroInput] = useState(formatter.format(500))
  const [showTooltip, setShowTooltip] = useState(false)

  const [euroOutput, setEuroOutput] = useState(
    rangeOutput > 30
      ? ((interest * parseLocaleNumber(euroInput, "de")) / 365) *
          (rangeOutput - 30).toFixed(2)
      : 0
  )

  useEffect(() => {
    expand(inputRef)
    expand(inputRef2)
    changePaymentHandler(type)
  }, [type])

  useEffect(() => {
    const numberEuroInput = parseLocaleNumber(euroInput, "de")
    if (
      type === "installments" &&
      numberEuroInput >= 100 &&
      numberEuroInput <= 50000
    ) {
      const marksChange = { 2: 2 }
      const max = Math.round(
        numberEuroInput / 50 > 48 ? 48 : numberEuroInput / 50
      )
      marksChange[max] = max
      setMaxRange(max)
      setMarks(marksChange)
    }
    if (numberEuroInput >= 100 && numberEuroInput <= 50000) {
      output(rangeOutput)
    }

    expand(inputRef)
  }, [euroInput])

  useEffect(() => {
    if (euroOutput) expand(inputRef2)
  }, [euroOutput])

  const expand = input => {
    const length = input.current.value.length
    let size = 5
    if (length == 0 || length == 1) {
      size = 1.6
    } else if (length > 4) {
      size = length
    } else if (length > 1) {
      size = length + 0.5
    }

    input.current.style.maxWidth = size + "ch"
  }
  const afterFocusOut = e => {
    if (e.target.value !== "") {
      const number = parseLocaleNumber(e.target.value, "de")
      if (number < 100) {
        setEuroInput(formatter.format(100))
        setShowTooltip(true)
      } else if (number > 50000) {
        setEuroInput(formatter.format(50000))
        setShowTooltip(true)
      } else {
        setEuroInput(formatter.format(number))
        setShowTooltip(false)
      }
      expand(inputRef)
    }
  }

  const changeHandler = e => {
    const re = /^-?\d*\.?\d*$/
    if (re.test(e.target.value)) {
      const number = parseLocaleNumber(e.target.value, "de")
      if (!number) {
        setEuroInput("")
        setShowTooltip(true)
      } else setEuroInput(formatter.format(number))
    }
  }

  const changePaymentHandler = typeValue => {
    const numberEuroInput = parseLocaleNumber(euroInput, "de")
    setType(typeValue)
    if (type == TYPE_LATER) {
      setMaxRange(60)
      setMinRange(2)
      setMarks({ 2: 2, 60: 60 })
    } else {
      const marksChange = { 2: 2 }
      const max = Math.round(
        numberEuroInput / 50 > 48 ? 48 : numberEuroInput / 50
      )
      marksChange[max] = max
      setMinRange(2)
      setMaxRange(max)
      setMarks(marksChange)
    }
    output()
  }

  const output = value => {
    const numberEuroInput = parseLocaleNumber(euroInput, "de")

    if (value) setRangeOutput(value)

    let rangeValue = value ? value : rangeOutput
    let equation
    if (type == TYPE_LATER) {
      equation =
        rangeValue > 30
          ? (((interest * numberEuroInput) / 365) * (rangeValue - 30)).toFixed(
              2
            )
          : 0
    } else {
      const monthlyInterest = interest / 12
      equation =
        numberEuroInput *
        ((monthlyInterest * Math.pow(1 + monthlyInterest, rangeValue)) /
          (Math.pow(1 + monthlyInterest, rangeValue) - 1))
      equation *= rangeValue
      equation -= numberEuroInput.toFixed(2)
    }
    setEuroOutput(isNaN(parseInt(equation)) ? 0 : formatter.format(equation))
    expand(inputRef2)
  }

  return (
    <div className="calculator">
      <div className="calculator__container">
        <div className="calculator__wrapper">
          <h3 className="calculator__heading">{heading}</h3>
          <div className="calculator__subheading">{subheading}</div>
          <div className="calculator__number-input-text calculator__label">
            {labelAmount}
          </div>
          <div
            className={`calculator__number-input is--input ${
              showTooltip ? "is--error" : ""
            }`}
          >
            <div className="calculator__input">
              <input
                ref={inputRef}
                value={euroInput}
                type="text"
                maxLength="6"
                onKeyUp={() => expand(inputRef)}
                // onKeyUp={e => afterFocusOut(e)}
                onChange={e => changeHandler(e)}
                onBlur={e => afterFocusOut(e)}
              />
              <span className="calculator__eurosign">€</span>
            </div>
            {showTooltip && (
              <div className="calculator__error">{inputTooltip}</div>
            )}
          </div>
          <div className="calculator__buttons-text-wrapper">
            <div className="calculator__buttons-text-heading calculator__label">
              {labelSelect}
            </div>
            <div className="calculator__buttons-wrapper">
              <div className="calculator__button-wrapper">
                <div
                  className={`button is--inverted ${
                    type == TYPE_LATER ? "is--active" : ""
                  }`}
                  onClick={() => setType(TYPE_LATER)}
                >
                  <SVGParser name="delay-payment" />
                  {buttonLabelLater}
                </div>
              </div>
              <div className="calculator__button-wrapper">
                <div
                  className={`button is--inverted ${
                    type == TYPE_INSTALLMENTS ? "is--active" : ""
                  }`}
                  onClick={() => setType(TYPE_INSTALLMENTS)}
                >
                  <SVGParser name="rates" />
                  {buttonLabelRates}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`calculator__number-range ${
              minRange === maxRange ? "is--max-width" : ""
            }`}
          >
            {type == TYPE_INSTALLMENTS && (
              <div className="calculator__range-input-text calculator__label">
                {labelRate}
              </div>
            )}
            {type == TYPE_LATER && (
              <>
                <div className="calculator__range-input-text calculator__label">
                  {labelPayLater}
                </div>
                <SVGParser name="for-free-badge" classes="calculator__badge" />
              </>
            )}
            <div className="calculator__range-wrapper">
              <Slider
                min={minRange}
                max={maxRange}
                value={rangeOutput}
                onChange={value => output(value)}
                handle={MyHandle}
                marks={marks}
              />
            </div>
          </div>
          <div className="calculator__number-output-text calculator__label">
            {labelCosts}
          </div>
          <div className="calculator__number-input is--output">
            <div className="calculator__input">
              <input type="text" disabled value={euroOutput} ref={inputRef2} />
              <span className="calculator__eurosign">€</span>
            </div>
          </div>
          <div>
            {button && (
              <UniversalLink className="button" to={button.url} id={buttonId}>
                {button.title}
              </UniversalLink>
            )}
          </div>
        </div>
        <div className="calculator__laws-wrapper">{ParseHTML(laws)}</div>
      </div>
    </div>
  )
}
