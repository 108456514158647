import React from "react"
import "./image-text-2.scss"
import contentParser from "gatsby-wpgraphql-inline-images"
import config from "../../../config"
import Image from "../../components/Image/Image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const ImageText2 = ({
  ACFImageText2: {
    heading,
    subheading,
    image,
    paragraph,
    reverseRow,
    withBackground,
  },
}) => {
  const breakpoints = useBreakpoint()

  let slideAnimation = breakpoints.md
    ? "slide-up"
    : reverseRow
    ? "slide-left"
    : "slide-right"

  let slideAnimationImage = breakpoints.md
    ? "slide-up"
    : reverseRow
    ? "slide-right"
    : "slide-left"

  return (
    <div className="image-text-2">
      <div className="container">
        <div className="image-text-2__cards-wrapper">
          <div
            className={`image-text-2__card-wrapper ${
              reverseRow ? "image-text-2__row-reverse" : ""
            }`}
          >
            <div className="image-text-2__text-wrapper">
              <div className="image-text-2__subheading">{subheading}</div>
              <h4 className="image-text-2__heading" data-sal={slideAnimation}>
                {heading}
              </h4>
              {paragraph && (
                <div
                  className="image-text-2__text"
                  data-sal={slideAnimation}
                  data-sal-delay={150}
                >
                  {contentParser(
                    { content: paragraph },
                    config.inlineImagesOptions
                  )}
                </div>
              )}
            </div>
            {image && (
              <div className="image-text-2__img-wrapper">
                <div data-sal={slideAnimationImage}>
                  <Image image={image} />
                </div>
                {withBackground && (
                  <div className="image-text-2__bg" data-sal="slide-up"></div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageText2
