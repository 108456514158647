import React, { useState } from "react"
import { Collapse } from "react-collapse"
import parseHTML from "html-react-parser"

const Question = ({ question }) => {
  const [show, setShow] = useState(false)

  question.description = question.description.replace(
    /<\/?span[^>]*>|<\/?p[^>]*>/g,
    ""
  )

  return (
    <div className="faq__question-answer-wrapper">
      <h6
        onClick={() => setShow(!show)}
        className={`faq__question ${show && "is--open"}`}
      >
        {question.title}
      </h6>
      <Collapse isOpened={show}>
        <div>
          <div className="faq__answer">{parseHTML(question.description)}</div>
        </div>
      </Collapse>
    </div>
  )
}

export default Question
