import React from "react"
import "./flexTeaser.scss"
import UniversalLink from "../../components/UniversalLink"
import SVGParser from "../../components/SVGParser"

const FlexTeaser = ({
  ACFFlexTeaser: {
    advantages,
    hasGreyBackground,
    heading,
    link,
    linkId,
    subheading,
    text,
  },
}) => {
  return (
    <div
      className={`flex-teaser ${
        hasGreyBackground ? "is--grey-background" : ""
      }`}
    >
      <div className="container">
        <div className={`flex-teaser__wrapper`}>
          <h3 className="flex-teaser__heading">{heading}</h3>
          <div className="flex-teaser__subheading">{subheading}</div>
          <div className="flex-teaser__text">{text}</div>
          <div
            className={`flex-teaser__advantages-wrapper ${
              advantages.length === 3 ? "is--three" : ""
            }`}
          >
            {advantages.map((advantage, index) => (
              <div
                className="flex-teaser__advantage-wrapper"
                key={index}
                data-sal="slide-up"
                data-sal-delay={150 * index}
              >
                <SVGParser
                  name={advantage.icon}
                  classes="flex-teaser__advantage-icon"
                />
                <h6 className="flex-teaser__advantage-heading">
                  {advantage.heading}
                </h6>
              </div>
            ))}
          </div>
          {link ? (
            <UniversalLink
              to={link.url}
              className="flex-teaser__button button"
              id={linkId}
            >
              {link.title}
            </UniversalLink>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default FlexTeaser
