import React from "react"
import Parse from "html-react-parser"
import "./fullWidthCard.scss"
import UniversalLink from "../../components/UniversalLink"

const FullWidthCard = ({
  ACFBlockFullWidthCard: { link, linkId, heading, subheading },
}) => {
  return (
    <div className="fullWidthCard">
      <div className="container">
        <div className="fullWidthCard__wrapper">
          <p className="fullWidthCard__heading">{heading}</p>
          <h3 className="fullWidthCard__subheading">{Parse(subheading)}</h3>
          {link && (
            <UniversalLink
              to={link.url}
              className="fullWidthCard__button button"
              id={linkId}
            >
              {link.title}
            </UniversalLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default FullWidthCard
