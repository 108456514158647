import React, { useState, useRef } from "react"
import "./videoBlock.scss"
import Image from "../../components/Image/Image"
import contentParser from "gatsby-wpgraphql-inline-images"
import config from "../../../config"
import UniversalLink from "../../components/UniversalLink"
import VideoOverlay from "../../components/VideoOverlay/VideoOverlay"
import SVGParser from "../../components/SVGParser"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const VideoText = ({
  ACFVideoTextBlock: {
    video,
    mobileVideo,
    subheading,
    paragraph,
    link,
    linkId,
    heading,
    imagePreview,
  },
}) => {
  const breakpoints = useBreakpoint()
  const [showOverlay, setShowOverlay] = useState(false)

  const vidRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPlayingImg, setIsPlayingImg] = useState(false)

  const videoWrapper = url => (
    <>
      {isPlayingImg && (
        <video
          ref={vidRef}
          loop
          autoPlay
          playsInline
          controls
          preload="metadata"
        >
          <source src={url} />
        </video>
      )}
      <SVGParser name={"play-circle"} classes={"play-button"} />
    </>
  )

  const handlePlayVideo = () => {
    if (vidRef.current && !isPlaying) {
      vidRef.current.play()
    } else if (vidRef.current && isPlaying && breakpoints.md) {
      vidRef.current.pause()
    }
    setIsPlaying(!isPlaying)

    if (breakpoints.md) setShowOverlay(true)
    setIsPlayingImg(true)
  }
  return (
    <div className="video-text">
      <div className="container">
        <div className="video-text__wrapper">
          <div className="video-text__video-text-wrapper">
            <h2 className="video-text__heading">{heading}</h2>
            {subheading && (
              <div className="video-text__subheading">{subheading}</div>
            )}
            {video && (
              <div className="video-text__video-wrapper">
                <div
                  className={`video-text__video ${isPlaying && "is--playing"} ${
                    isPlayingImg && "is--playing-img"
                  }`}
                  onClick={() => {
                    !showOverlay && handlePlayVideo()
                  }}
                >
                  {imagePreview && <Image image={imagePreview} />}
                  {breakpoints.md ? (
                    <VideoOverlay
                      showOverlay={showOverlay}
                      setShowOverlay={setShowOverlay}
                      handlePlayVideo={handlePlayVideo}
                      setIsPlayingImg={setIsPlayingImg}
                      setIsPlaying={setIsPlaying}
                    >
                      {videoWrapper(mobileVideo.mediaItemUrl)}
                    </VideoOverlay>
                  ) : (
                    videoWrapper(video.mediaItemUrl)
                  )}
                  <SVGParser name={"play-circle"} classes={"play-button"} />
                </div>
              </div>
            )}
            {paragraph ? (
              <div className="video-text__text">
                {contentParser(
                  { content: paragraph },
                  config.inlineImagesOptions
                )}
              </div>
            ) : null}
          </div>
        </div>
        <div className="video-text__button-wrapper">
          {link && (
            <UniversalLink to={link.url} className="button" id={linkId}>
              {link.title}
            </UniversalLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default VideoText
