import React from "react"
import "./partners.scss"
import Image from "../../components/Image/Image"
const Partners = ({ ACFPartnersBlock: { title, logoOne, logoTwo } }) => {
  return (
    <div className="partners">
      <div className="container">
        <div className="partners__wrapper">
          <h3 className="partners__heading">{title}</h3>
          <div className="partners__images-wrapper">
            <div className="partners__img-wrapper">
              <div className="partners__background">
                <Image image={logoOne} classes="partners__img" />
              </div>
            </div>
            <div className="partners__img-wrapper">
              <div className="partners__background">
                <Image image={logoTwo} classes="partners__img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
