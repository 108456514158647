import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MobilePricing from "./MobilePricing"
import DesktopPricing from "./DesktopPricing"
const Pricing = ({ ACFPricing}) => {
  const breakpoints = useBreakpoint()

  return (
   breakpoints.lg ? <MobilePricing ACFPricing={ACFPricing} /> : <DesktopPricing ACFPricing={ACFPricing} />
  )
}

export default Pricing
