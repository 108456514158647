import React from "react"
import "./benefits.scss"
import parseHTML from "html-react-parser"

const Benefits = ({
  ACFBenefitsBlock: { heading, subheading, text, benefits },
}) => {
  return (
    <div className="benefits">
      <div className="container">
        <div className="benefits__wrapper">
          <div className="benefits__text-content">
            <h5 className="benefits__heading">{parseHTML(heading)}</h5>
            <div className="benefits__subheading">{subheading}</div>
            <div className="benefits__text">{parseHTML(text)}</div>
          </div>
          <div className="benefits__benefits-wrapper">
            {benefits.map(benefit => (
              <div className="benefits__benefit-wrapper">
                <div className="benfits__benefit-container">
                  <div className="benefits__benefit-heading">
                    {benefit.heading}
                  </div>
                  <div className="benefits__benefit-subheading">
                    {benefit.subheading}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Benefits
