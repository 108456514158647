import React from "react"
import "./desktop-pricing.scss"
import UniversalLink from "../../components/UniversalLink"
import Parse from "html-react-parser"
import SVGParser from "../../components/SVGParser"

const DesktopPricing = ({ ACFPricing: { note, cards, features } }) => {
  const isHTML = arr => {
    const check = Array.isArray(arr) && arr.find(el => el.type) ? true : false
    return check
  }
  return (
    <div className="desktop-pricing">
      <div className="container">
        <div className="desktop-pricing__cards-wrapper">
          {cards.map((card, index) => (
            <div className={`desktop-pricing__card-wrapper`} key={index}>
              <div className="desktop-pricing__card-details-wrapper">
                <h3 className="desktop-pricing__card-headline">
                  {card.heading}
                </h3>
                <div className="desktop-pricing__card-subheadline">
                  {card.text}
                </div>
                <div className="price-tag">
                  {card.price ? card.price : "0"}
                  {card.cents && <span className="cents">{card.cents}</span>}
                  <span>€</span>
                </div>
                <div className="price-tag-helper">Pro Monat / exkl. USt.</div>
                {card.link && (
                  <UniversalLink
                    className="desktop-pricing__card-url button"
                    to={`${card.link.url}`}
                    id={card.linkId}
                  >
                    {card.link.title}
                  </UniversalLink>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="desktop-pricing__features-wrapper">
          {features.map((feature, index) => (
            <div key={index} className={`desktop-pricing__row`}>
              <div className="desktop-pricing__cell-wrapper">
                <div className="desktop-pricing__feature-name">
                  {feature.featureName}
                  {feature.featureNote && (
                    <span className="desktop-pricing__tooltip">
                      <SVGParser name="info-circle" />
                      <span className="desktop-pricing__tooltiptext">
                        {feature.featureNote}
                      </span>
                    </span>
                  )}
                </div>
              </div>
              <div className="desktop-pricing__cell-wrapper">
                <div className="desktop-pricing__yes-no-wrapper">
                  {feature.basic == "yes" ? (
                    <SVGParser name="check-outline" />
                  ) : feature.basic == "no" ? (
                    <SVGParser name="x-circle" />
                  ) : isHTML(Parse(feature.basic)) ? (
                    <div className="desktop-pricing__text">
                      {Parse(feature.basic)}
                    </div>
                  ) : (
                    feature.basic
                  )}
                </div>
              </div>
              <div className="desktop-pricing__cell-wrapper">
                <div className="desktop-pricing__yes-no-wrapper">
                  {feature.flex === "yes" ? (
                    <SVGParser name="check-outline" />
                  ) : feature.flex === "no" ? (
                    <SVGParser name="x-circle" />
                  ) : isHTML(Parse(feature.flex)) ? (
                    <div className="desktop-pricing__text">
                      {Parse(feature.flex)}
                    </div>
                  ) : (
                    feature.flex
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {note && <div className="desktop-pricing__note">{Parse(note)}</div>}
      </div>
    </div>
  )
}

export default DesktopPricing
